.dk-portalMain {
    position: relative;
    padding: 155px 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .dk-portalContainer {
        width: 100%;
        max-width: 1250px;
        margin: auto;

        h3.title {
            display: block;
            margin-bottom: 0;
            margin-top: 0;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            color: #333;
            position: absolute;
            left: 38%;
            transform: translate(-50%);
            top: 10px;
        }

        h4.sbtitle {
            text-align: right;
            position: absolute;
            right: 30px;
            top: 15px;
            font-size: 22px;
            font-weight: 600;
            color: #333;
        }

        .dk-topHeadTitle {
            position: relative;
            padding: 10px 0 40px;
            z-index: 9;
        }

        .dk-portalCols {
            width: 100%;
            display: flex;
            justify-content: center;

            .dk_portalBox_1 {
                background: #3e4c60;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_2 {
                background: #144773;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_3 {
                background: #a43b63;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_4 {
                background: #5C737D;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_5 {
                background: #57496D;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_6 {
                background: #CF7C49;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_7 {
                background: #38607C;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_8 {
                background: #2E75B6;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_9 {
                background: #30887E;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_10 {
                background: #535255;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_11 {
                background: #297483;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_12 {
                background: #483252;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_13 {
                background: #2B8FAF;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_14 {
                background: #3e4c60;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_15 {
                background: #3e4c60;
                border: 1px solid #ddd;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_16 {
                background: #eaa035;
                border: 1px solid #ddd;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_17 {
                background: #657D74;
                border: 1px solid #ddd;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_18 {
                background: #a16e4d;
                border: 1px solid #ddd;

                a {
                    color: #fff;
                }
            }
        }

        .dk-portalBox {
            width: 100%;
            max-width: 29.33%;
            min-height: 140px;
            background: #eee;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;

            div {
                display: block;
                width: 100%;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #333;
                width: 100%;
                height: 100%;

                &:hover .dk-Portalicons img {
                    transition: all 0.5s;
                    transform: scale(0.88);
                }
            }

            .dk-Portalicons {
                text-align: center;
                margin-bottom: 10px;

                img {
                    width: 100%;
                    max-width: 40px;
                    transition: all 0.5s;
                    transform: scale(1);
                }
            }

            .dk-Portalicons.PortaliconsBtn {
                button {
                    background: #f2f2f2;
                    border: none;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    border-radius: 3px;
                    margin: 0 2px;
                    outline: none;
                    color: #067185;
                }
            }

            .dk-PortalTitle {
                font-size: 17px;
                text-transform: capitalize;
                font-weight: 400;
                display: block;
                text-align: center;
            }
        }
    }
}


.dk-portalMain {

    // padding: 60px 0;
    // padding-bottom: 204px;
    // background-color: #d8d8d8;
    .enrolment_process_img_sec {
        width: 100%;
        min-height: 622px;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .enrolment_process_content {
        position: absolute;
        top: -115px;
        left: 0;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                width: 410px;
                position: absolute;
                list-style-type: none;

                .enrolment_icon_sec {
                    position: relative;
                    float: right;
                    margin-left: 15px;

                    img {
                        max-width: 100%;
                    }
                }

                .enrolment_icon {
                    position: absolute;
                    content: "";
                    top: 31%;
                    left: -12px;
                    right: 0;
                    margin: 0 auto;
                    display: inline-block;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    text-align: center;
                }

                &:nth-child(01) {
                    left: 605px;
                    top: 120px;

                    .enrolment_icon_sec {
                        float: left;
                        margin-right: 15px;
                        -webkit-transform: rotate(124deg);
                        transform: rotate(124deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-124deg);
                        transform: rotate(-124deg);
                    }
                }

                &:nth-child(02) {
                    left: 762px;
                    top: 310px;

                    .enrolment_icon_sec {
                        float: left;
                        margin-left: 0;
                        margin-right: 15px;
                        -webkit-transform: rotate(176deg);
                        transform: rotate(176deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-176deg);
                        transform: rotate(-176deg);
                    }
                }

                &:nth-child(03) {
                    top: 542px;
                    left: 714px;

                    .enrolment_icon_sec {
                        float: left;
                        margin-left: 0;
                        margin-right: 15px;
                        -webkit-transform: rotate(224deg);
                        transform: rotate(224deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-224deg);
                        transform: rotate(-224deg);
                    }
                }

                &:nth-child(04) {
                    text-align: center;
                    top: 634px;
                    left: 440px;
                    width: 257px;
                    text-align: center;

                    .enrolment_icon_sec {
                        position: relative;
                        float: none;
                        margin-left: 0;
                        margin-right: 15px;
                        -webkit-transform: rotate(270deg);
                        transform: rotate(270deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-270deg);
                        transform: rotate(-270deg);
                    }
                }

                &:nth-child(05) {
                    left: 5px;
                    top: 522px;
                    text-align: right;
                    text-align: right;

                    .enrolment_icon_sec {
                        -webkit-transform: rotate(-39deg);
                        transform: rotate(-39deg);
                        float: right;
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(39deg);
                        transform: rotate(39deg);
                    }
                }

                &:nth-child(06) {
                    text-align: right;
                    top: 300px;
                    left: -34px;

                    .enrolment_icon_sec {
                        -webkit-transform: rotate(7deg);
                        transform: rotate(7deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-7deg);
                        transform: rotate(-7deg);
                    }
                }

                &:nth-child(07) {
                    text-align: right;
                    top: 120px;
                    left: 105px;

                    .enrolment_icon_sec {
                        -webkit-transform: rotate(58deg);
                        transform: rotate(58deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-58deg);
                        transform: rotate(-58deg);
                        top: 36%;
                    }
                }

                .enrolment_text {
                    h4 {
                        font-size: 16px;
                        font-weight: 800;
                        text-transform: uppercase;
                        color: #003f51;
                        margin: 0 0 5px;
                        padding-top: 15px;
                    }

                    p {
                        line-height: 20px;
                        color: #333 !important;
                        margin: 0;
                        font-weight: 400;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.dk-mdHeaderMain {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    padding: 0 15px;
    width: 100%;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: none;
    background: #fff;
    border-bottom: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

    &.dk-dkgcrm-header {
        padding-left: 0 !important;

        @media(max-width:992px) {
            padding: 0 !important;
            display: none;
            .dk-mbtopheaderCon {
                flex-wrap: wrap;

                .dk-moduleLogo {
                    margin: 10px 5px;
                }

                .dk-mdHeader-rightPanel {
                    justify-content: space-between;
                    padding-right: 15px;
                }
            }
            &.dkg-crm-mobile-header{
                display: block !important;
            }
        }
    }

    .dk-mbtopheaderCon {
        position: relative;

        .dk-moduleLogo {
            width: 162px;

            a {
                display: inline-block;

                img {
                    width: 100% !important;
                    height: 45px;
                    display: inline-block;
                    margin-top: 1px;
                }
            }
        }

        .titleClient {
            display: inline-block;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 18px;
            font-weight: 500;
            top: 17px;
            color: #333;
        }

        .dk-mdHeader-rightPanel {
            display: flex;
            align-items: center;

            @media(max-width:992px) {
                width: 100%;
                border-top: 1px solid #ddd;
            }

            .dk-btnsClient {
                display: inline-block;
                margin-right: 10px;

                a {
                    display: inline-block;
                    width: 47px;
                    height: 53px;
                    line-height: 54px;
                    border-right: 1px solid #ddd;
                    text-align: center;
                    color: #067185;
                    margin-right: 0;
                    font-size: 18px;

                    &:first-child {
                        border-left: 1px solid #ddd;

                        @media(max-width:992px) {
                            border-right: none;
                            border-left: none !important;
                            border-right: 1px solid #ddd;
                        }
                    }

                    &:hover {
                        background: #f2f2f2;
                        transition: all 0.5s;
                    }
                }

                .dk-unreadEmails {
                    position: relative;

                    span.dk-unreadEmails-small {
                        font-size: 10px;
                        display: inline-block;
                        text-align: center;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background: #e41e3f;
                        position: absolute;
                        color: #fff;
                        line-height: 15px;
                        top: 11px;
                        right: 5px;
                    }
                }
            }

            .dropdown {
                .dropdown-toggle.btn {
                    padding: 0;
                    background: transparent !important;
                    box-shadow: none;
                    color: #131212;
                    font-size: 15px;
                    text-transform: none;

                    &::after {
                        display: none;
                    }

                    span {
                        width: 35px;
                        margin-right: 8px;

                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                        }
                    }
                }

                .dropdown-menu {
                    border-radius: 0;
                    border: none;
                    box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.30);
                    transform: inherit !important;
                    top: 45px !important;
                    left: -9px !important;
                    min-width: 158px;
                    padding: 0;
                    z-index: 999;

                    a.dropdown-item {
                        padding: 8px 12px;
                        border-bottom: 1px solid #ddd;
                        display: block;
                        width: 100%;

                        &:hover {
                            background: #ddd;
                            transition: all 0.5s;
                        }

                        i {
                            display: inline-block;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

}

.headerTitle {
    font-size: 17px;
    color: red;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    a {
        font-weight: 500;
    }

    @media(max-width:600px) {
        display: none;
    }

    a {
        &:hover {
            color: #067185 !important;
        }
    }

    .dk-all-recruiterListCon {
        ul {
            display: flex;
            align-items: center;
            list-style: none;
            margin-bottom: 0;

            li {
                margin: 0 7px;

                a {
                    position: relative;

                    img.recruiter_img {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        border: 1px solid #ddd;
                        margin-top: 13px;
                    }

                    .taskcounter {
                        position: absolute;
                        top: -17px;
                        right: 5px;
                        width: 23px;
                        height: 23px;
                        border-radius: 50%;
                        color: #234158;
                        // background-color: #fff;
                        // box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
                        font-size: 9px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.bg-pink-400.dk-mb-headerbadge {
    background: #ec407a;
    border-radius: 50% !important;
    font-size: 11px !important;
    box-shadow: none;
    font-weight: normal;
    padding: .3125rem .4375rem
}

.dk-mbnewloginbtnCon button {
    border: none;
    border-radius: 0;
    background-color: #2b7a8a !important;
    color: #fff;
    text-transform: capitalize;
    font-size: 18px;
    padding: 8px 15px;
}

.dk-mbnewloginbtnCon button.activeLoginBtn {
    background-color: #10333c !important;
}

.dk-loginTabsMain {
    width: 100%;
    max-width: 365px;
    margin: auto;

    .dk-mbnewloginbtnCon {
        margin: 35px 0 30px;

        button {
            font-weight: 400;
        }
    }
}

.MuiPopover-root {
    z-index: 99999 !important;
}

#dk-candidateprescreen-popover {
    .MuiPaper-root {
        color: #fff;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #396A95;
    }
}

.dk-navBarMain.dk-resourcesNavbar {
    ul {
        li {
            a.grey-active {
                background: #eaeaea;
                color: #333;
            }
        }
    }
}

.dk-click {
    display: inline-block;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 999;
    background: #067185;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    font-size: 13px;
    display: none;
    &:hover {
        color: #fff;
        background: #244158;
    }
}